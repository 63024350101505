import { DefineComponent } from 'vue';
import { reinitializeVueComponent } from '@/js/pages/helpers/infinite-scroll/reinitializeVueComponent';
import SubscribeNewsletterArticle from '@/js/subscribe/SubscribeNewsletterArticle.vue';

type SubscribeNewsletterArticleComponent = DefineComponent<
    typeof SubscribeNewsletterArticle['$props'],
    typeof SubscribeNewsletterArticle['$data'],
    typeof SubscribeNewsletterArticle['$computed'],
    typeof SubscribeNewsletterArticle['$methods']
>;

reinitializeVueComponent<SubscribeNewsletterArticleComponent>(SubscribeNewsletterArticle);
